
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class Help extends Vue {
  created() {
    this.$meta.setMeta({ title: ['Help'] })
  }
}
